import { Hero } from "../components/Hero";
import Container from "@mui/material/Container";
import { Section } from "../components/Section";
import { content } from "../content/content-partners";
import { SectionContainer } from "../components/SectionContainer";
import { Calendar } from "../components/Calendar";
import { dates } from "../content/dates";
import { Footer } from "../components/Footer";
import { useRef } from "react";

function Partners() {
  const contactRef = useRef(null);

  return (
    <>
      <Hero
        image="/img/cropped-graduate-AdobeStock_358963282_3200x1730.jpg"
        claim={`IT-Fachkraft,\nTeamplayer,\nMotivation\nbewiesen!`}
        xsTop={"220px"}
        xsLeft={"20px"}
        xsTextAlign={"center"}
        smTop={"200px"}
        smLeft={"12vw"}
        backgroundPosition={"70% 10%"}
      />
      <Container component="main">
        <SectionContainer>
          <Section sectionData={content.firstInBonn} colspan={2} />
          <Section sectionData={content.freshMinds} />
          <Section sectionData={content.orRefreshment} />
        </SectionContainer>
        <Hero
          image="/img/cropped-course-marvin-meyer-SYTO3xs06fU-unsplash_3200x1730.jpg"
          claim={`Anpacken gelernt`}
          xsTop={"180px"}
          xsLeft={"40px"}
          smTop={"180px"}
          smLeft={"150px"}
        />
        <SectionContainer>
          <Section sectionData={content.threeGoals} colspan={2} />
        </SectionContainer>

        <Hero
          ref={contactRef}
          image="/img/cropped-desola-lanre-ologun-IgUR1iX0mqM.jpg"
          claim={`Berufliche\nWeiterbildung`}
          xsTop={"200px"}
          xsLeft={"20px"}
          xsTextAlign={"left"}
          smTop={"40px"}
          smLeft={"8vw"}
          smTextAlign={"left"}
          backgroundPosition={"100%"}
        />
        <SectionContainer>
          <Section sectionData={content.learningOnTheJob} colspan={2} />
        </SectionContainer>

        <Hero
          image="/img/cropped-dates-jason-goodman-0K7GgiA8lVE-unsplash_3200x1730.jpg"
          claim={`Termine`}
          xsTop={"390px"}
          xsLeft={"130px"}
          smTop={"180px"}
          smLeft={"calc(20px + 10vw)"}
          backgroundPosition={"35%"}
        />
        <Calendar
          dates={dates}
          type="partners"
          onSelectInfo={() => {
            if (contactRef) {
              contactRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }}
        />
        <Hero
          ref={contactRef}
          image="/img/cropped-contact-AdobeStock_352375722_3200x1730.jpg"
          claim={`Mehr Infos\nJederzeit`}
          xsTop={"150px"}
          xsRight={"20px"}
          xsTextAlign={"right"}
          smTop={"180px"}
          smLeft={"8vw"}
          smTextAlign={"left"}
          backgroundPosition={"100%"}
        />
        <SectionContainer>
          <Section sectionData={content.contact} colspan={2} />
        </SectionContainer>
        <Hero
          image="/img/cropped-new-employee-AdobeStock_525028049_3200x1730.jpg"
          opacity={0.2}
          claim={`Und wieder\nein Talent\nan Bord.`}
          xsTop={"210px"}
          xsRight={"20px"}
          xsTextAlign={"right"}
          smTop={"240px"}
          smLeft={"25vw"}
          smTextAlign={"left"}
          backgroundPosition={"77%"}
        />
        <Footer omit="partners" />
      </Container>{" "}
    </>
  );
}

export default Partners;
