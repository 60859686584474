import AnchorLink from "react-anchor-link-smooth-scroll";
import { styled } from "@mui/material/styles";

export const StyledAnchorLink = styled(AnchorLink)`
  background-color: ${({ cta }) => (cta ? "#ea5153" : "#11acff")};
  color: white;
  padding: ${({ cta }) => (cta ? "6px 18px" : "4px 12px")};
  border-radius: 4px;
  min-width: 64px;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  &:hover {
    background-color: ${({ cta }) => (cta ? "#bb4142" : "#0a8de4")};
  }
  &:active {
    transition: all 0.2s;
    box-shadow: none;
    transform: scale(0.98);
  }
`;
