export const content = {
  firstHandFeedback: {
    headline: "Feedback aus erster Hand",
    columns: [
      `
Wir bitten unsere Teilnehmer:innen regelmäßig, Ihre Erfahrungen und Eindrücke zu teilen. 
So helfen sie uns, das Bootcamp stetig zu verbessern und Interessent:innen wie Dir, sich ein 
Bild von der Maßnahme zu machen.\n
*Wie geht es Dir im Bootcamp?*\n
*Ist es leichter oder anstrengender, als Du gedacht hast?*\n
*Hattest Du vor dem Bootcamp schon Programmier-Erfahrung?*\n
    `,
      `
*Was war der schönste Moment im Bootcamp? Und was der Schwierigste?*\n
*Wieviel Zeit investierst Du in die Ausbildung?*\n
*War es schwierig, einen Bildungsgutschein zu bekommen, der das Bootcamp finanziert?*\n
*Was rätst Du Interessenten, die unsicher sind, ob das Bootcamp das Richtige für sie ist?*\n
Antworten auf diese und viele andere Fragen für Euch im Video. `,
    ],
  },
  faq: {
    headline: "Frequently asked questions",
    columns: [
      `
In einen neuen Job durchzustarten will gut überlegt sein. Deshalb stellen uns 
Interessenten viele und gute Fragen.
    `,
      `
Unsere Coaches Ralf und Silke beantworten im Video ein paar der häufigsten Fragen.
Sprich uns gerne an, wenn Du weitere Fragen hast. 
      `,
    ],
  },
  demo: {
    headline: "Demo coding session",
    columns: [
      `
Viele Interessenten kommen ganz ohne Programmier-Vorwissen zu uns. Das ist für einen
Einstieg in die IT auch nicht nötig. Es braucht nur ein gewisses Talent und die
entsprechende Motivation.
    `,
      `
Damit Du aber überhaupt mal eine Idee bekommst, wie Programmcode aussieht und was man
Programmieren kann, haben wir eine 30-minütige Demo Session aufgenommen. Nach 4 Wochen
im Bootcamp seid Ihr in der Lage, so eine Mini-App zu bauen - und noch viel mehr...
      `,
    ],
  },
};
