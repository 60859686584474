// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { useState, useEffect } from "react";

import SwiperCore from "swiper/core";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Slider.css";

SwiperCore.use([Pagination, Navigation]);

export function Slider({
  slidesPerView = 3,
  gap = 20,
  data,
  renderItem,
  keyExtractor = ({ id }) => id,
}) {
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0);
    }
  }, [data, swiper]);
  return (
    <div className="swiper-container">
      {/* <div className="swiper-button-prev"></div> */}
      <Swiper
        onSwiper={setSwiper}
        onActiveIndexChange={({ activeIndex }) => {
          console.log("active index change");
        }}
        spaceBetween={gap}
        slidesPerView={slidesPerView}
        onSlideChange={() => console.log("slide change")}
        pagination={{ clickable: true }}
        preventInteractionOnTransition
        navigation={{
          enabled: true,
        }}
        modules={[Pagination, Navigation]}
      >
        {data.map((item) => (
          <SwiperSlide key={keyExtractor(item)}>{renderItem(item)}</SwiperSlide>
        ))}
      </Swiper>
      {/* <div className="swiper-button-next"></div> */}
    </div>
  );
}
