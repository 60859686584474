import { StyledMarkdown } from "../StyledMarkdown/StyledMarkdown";
import { styled, keyframes } from "@mui/material";
import { Grid } from "@mui/material";

export const Section = ({ sectionData, colspan = 1, animate = false }) => {
  return (
    <AnimatedGrid
      animate={animate}
      item
      container
      component="section"
      xs={12}
      md={colspan * 6}
      columnSpacing={5}
      sx={{
        alignContent: colspan === 1 ? "flex-start" : "normal",
        marginBottom: 3,
      }}
    >
      <Grid xs={12} item>
        <h2>{sectionData.headline}</h2>
      </Grid>
      <Grid xs={12} md={colspan === 2 ? 6 : 12} item sx={{ padding: 0 }}>
        <StyledMarkdown>{sectionData.columns[0]}</StyledMarkdown>
      </Grid>
      {colspan === 2 && (
        <Grid xs={12} md={6} item sx={{ padding: 0 }}>
          <StyledMarkdown>{sectionData.columns[1]}</StyledMarkdown>
        </Grid>
      )}
    </AnimatedGrid>
  );
};

const animation = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`;

const AnimatedGrid = styled(Grid)`
  animation-name: ${({ animate }) => (animate ? animation : undefined)};
  animation-duration: ${({ animate }) => (animate ? "3s" : 0)};
`;

export const SectionAlternative = ({ sectionData }) => {
  return (
    <Grid
      item
      container
      component="section"
      xs={12}
      columnSpacing={5}
      sx={{
        alignContent: "flex-start",
        marginBottom: 5,
      }}
    >
      <Grid xs={12} item>
        <h2>{sectionData.headline}</h2>
      </Grid>
      <Grid xs={12} item sx={{ padding: 0 }}>
        <StyledMarkdown>{sectionData.columns[0]}</StyledMarkdown>
      </Grid>
    </Grid>
  );
};
