import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ClickAwayListener from "@mui/base/ClickAwayListener";

import "./navigation.css";

const sections = [
  { anchor: "/#intro", title: "Bootcamp? Was ist das?" },
  { anchor: "/#testimonials", title: "Was sagen die Teilnehmer:innen?" },
  { anchor: "/#costs", title: "Was kostet das denn?" },
  { anchor: "/#schedule", title: "Dein Lern-Alltag" },
  { anchor: "/#curriculum", title: "Das Curriculum" },
  { anchor: "/#dates", title: "Termine" },
  { anchor: "/#coaches", title: "Trainer kennenlernen" },
  { seperator: true },
  { anchor: "/videos", title: "Videos" },
  { seperator: true },
  { anchor: "/partner", title: "Infos für Partner-Unternehmen" },
  { anchor: "/vermittler", title: "Infos für Arbeitsvermittler" },
];

export default function Navigation() {
  const [showMobileMenuLayer, setShowMobileMenuLayer] = useState(false);

  window.onscroll = function () {
    if (document.documentElement.scrollTop > 50) {
      setShowMobileMenuLayer(false);
    }
  };

  const handleClickAway = () => {
    setShowMobileMenuLayer(false);
  };

  function thisAnchor(navItem) {
    return (
      <a
        href={navItem.anchor}
        onClick={() => {
          setShowMobileMenuLayer(false);
        }}
        // className={`${navBg ? "navigation-textcolor-docked" : null}`}
        style={{
          color: "inherit",
          textDecoration: "inherit",
          whiteSpace: "nowrap",
        }}
      >
        {navItem.title}
      </a>
    );
  }
  function renderMobileMenuLayer() {
    return (
      <ClickAwayListener
        onClickAway={handleClickAway}
        style={{ position: "relative" }}
      >
        <div className="nav-pos-mobile-layer">
          {sections.map((item) => {
            if (item.seperator) return <div style={{ height: 12 }} />;
            else return <div className="navigation-li">{thisAnchor(item)}</div>;
          })}
        </div>
      </ClickAwayListener>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <MenuIcon
        //   className="nav-pos-mobile-hamburger"
        style={{
          cursor: "pointer",
          fontSize: "2.5rem",
          marginRight: "3vw",
        }}
        onClick={() => {
          setShowMobileMenuLayer(!showMobileMenuLayer);
        }}
      />
      {showMobileMenuLayer ? renderMobileMenuLayer() : false}
    </div>
  );
}
