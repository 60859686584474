import { forwardRef } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { useRatioPanorama } from "../../hooks/useRatioPanorama";

export const Hero = forwardRef(
  (
    {
      image,
      opacity,
      backgroundPosition,
      claim,
      xsTop,
      xsLeft,
      xsRight,
      xsTextAlign,
      smTop,
      smLeft,
      smRight,
      smTextAlign,
      mdTop,
      mdLeft,
      mdRight,
      mdTextAlign,
      borderRadius = 0,
    },
    ref
  ) => {
    // console.log("mdTop ", { mdTop });
    let panorama = useRatioPanorama();

    return (
      <StyledContainer
        image={image}
        backgroundposition={backgroundPosition}
        opacity={opacity}
        ref={ref}
        borderRadius={borderRadius}
      >
        <Container>
          <StyledClaim
            panorama={panorama}
            component="h1"
            xstop={xsTop}
            xsleft={xsLeft}
            xsright={xsRight}
            xstextalign={xsTextAlign}
            smtop={smTop}
            smleft={smLeft}
            smright={smRight}
            smtextalign={smTextAlign}
            mdtop={mdTop}
            mdleft={mdLeft}
            mdright={mdRight}
            mdtextalign={mdTextAlign}
          >
            {claim}
          </StyledClaim>
        </Container>
      </StyledContainer>
    );
  }
);

const StyledClaim = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "panorama",
})`
  color: white;
  font-size: ${() => {
    const ratio = window.innerWidth / window.innerHeight;
    return ratio > 1.5 ? "calc(0.5em + 6vh)" : "3rem";
  }};
  font-weight: 600;
  font-style: italic;
  white-space: pre-wrap;
  line-height: 1.2;
  position: absolute;
  top: ${({ xstop, panorama }) => {
    return panorama ? `calc(${xstop} * 0.6)` : xstop;
  }};
  left: ${({ xsleft }) => xsleft};
  right: ${({ xsright }) => xsright};
  text-align: ${({ xstextalign }) => xstextalign};
  ${({ theme }) => theme.breakpoints.up("sm")} {
    top: ${({ smtop, panorama }) => {
      return panorama ? `calc(${smtop} * 0.6)` : smtop;
    }};
    left: ${({ smleft }) => smleft};
    right: ${({ smright }) => smright};
    text-align: ${({ smtextalign }) => smtextalign};
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    top: ${({ mdtop, panorama }) => {
      return panorama ? `calc(${mdtop} * 0.6)` : mdtop;
    }};
    left: ${({ mdleft }) => mdleft};
    right: ${({ mdright }) => mdright};
    text-align: ${({ mdtextalign }) => mdtextalign};
  }
`;

const StyledContainer = styled("div")`
  border-radius: ${({ borderRadius }) => borderRadius}px;
  height: calc(100vh - ${({ theme }) => theme.mixins.toolbar.minHeight}px);
  max-height: 480px;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  // background-image: linear-gradient(rgba(0, 0, 0, 0.2, rgba(0, 0, 0, 0.2)),
  background-image: url(${({ image }) => image});
  background-position: ${({ backgroundposition }) => backgroundposition};
  background-repeat: no-repeat;
  background-size: cover;
  ${({ theme }) => theme.breakpoints.up("md")} {
    left: 0;
    width: 100%;
  }
`;
