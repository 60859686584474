import { Outlet } from "react-router-dom";
import { Header } from "../components/Header";
import { Toolbar } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Root = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header
        showNav={pathname !== "/bewerbung"}
        showCTA={
          pathname === "/" ||
          pathname === "/students" ||
          pathname === "/teilnehmer"
        }
      />
      <Toolbar />
      <Outlet />
    </>
  );
};
export default Root;
