import React from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Global } from "@emotion/react";
import { globalStyles } from "./components/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import Videos from "./pages/Videos";
import Students from "./pages/Students";
import Partners from "./pages/Partners";
import JobAdvisers from "./pages/JobAdvisers";
import Application from "./pages/Application";
import Root from "./pages/Root";
import { FileNotFound } from "./components/FileNotFound";
import ReactGA from "react-ga4";

ReactGA.initialize("G-72LVLK69M7");

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Root />
      </>
    ),
    children: [
      {
        path: "",
        element: <Students />,
      },
      {
        path: "students",
        element: <Students />,
      },
      {
        path: "teilnehmer",
        element: <Students />,
      },
      {
        path: "partner",
        element: <Partners />,
      },
      {
        path: "agentur",
        element: <JobAdvisers />,
      },
      {
        path: "arbeitsvermittler",
        element: <JobAdvisers />,
      },
      {
        path: "vermittler",
        element: <JobAdvisers />,
      },
      {
        path: "videos",
        element: <Videos />,
      },

      {
        path: "bewerbung",
        element: (
          <>
            <Application />
          </>
        ),
      },
      { path: "*", element: <FileNotFound /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <Global styles={globalStyles} />
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
