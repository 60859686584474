import React from "react";

// TODO: Tweak this page

export const FileNotFound = () => {
  return (
    <>
      <div style={{ backgroundColor: "#f2f2f2", width: "100%" }}>
        File not found
      </div>
    </>
  );
};
