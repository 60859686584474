import Container from "@mui/material/Container";
import { VideoPlayer } from "../components/VideoPlayer/VideoPlayer";
import { Section, SectionAlternative } from "../components/Section";
import { content } from "../content/content-videos";
import { SectionContainer } from "../components/SectionContainer";
import { Grid } from "@mui/material";
import React from "react";
import { Footer } from "../components/Footer";

function Videos() {
  return (
    <>
      <Container component="main">
        <div id="students"></div>
        <SectionContainer paddingBottom={0}>
          <Section sectionData={content.firstHandFeedback} colspan={2} />
        </SectionContainer>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            paddingBottom: 20,
          }}
        >
          <VideoPlayer url="https://www.youtube.com/watch?v=kYh2aAKAceU" />
          <VideoPlayer url="https://www.youtube.com/watch?v=VH-Rm6emTl8" />
        </Grid>

        <div id="faq"></div>
        <SectionContainer paddingBottom={0}>
          <Section sectionData={content.faq} colspan={2} />
        </SectionContainer>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            paddingBottom: 20,
          }}
        >
          <VideoPlayer
            url="https://www.youtube.com/watch?v=Eo8BrGShwVI"
            width={539}
            height={350}
          />
        </Grid>

        <div id="demo"></div>
        <SectionContainer paddingBottom={0}>
          <Section sectionData={content.demo} colspan={2} />
        </SectionContainer>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            paddingBottom: 20,
          }}
        >
          <VideoPlayer
            url="https://www.youtube.com/watch?v=jjwaNxVhSn8"
            width={539}
            height={350}
          />
        </Grid>

        <Footer omit="students" />
      </Container>
    </>
  );
}

export default Videos;
