import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useRef, useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

export function PartnerBox({
  teaserItems,
  containerStyle = {},
  textStyle = {},
}) {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const scrollContainerRef = useRef(null);

  const imageMargin = isMobile ? 120 : isLarge ? 320 : 240;
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let scrollAmount = 0;
    let step = 0.3; // Adjust this value to change the scrolling speed

    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isSafari =
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      !/chrome/i.test(navigator.userAgent);
    if (isSafari) {
      step *= 2; // Increase step value for Safari for equivalent speed; adjust as needed
    }
    console.log("isSafari", isSafari);

    function scrollHorizontally() {
      if (scrollContainer) {
        if (scrollAmount >= scrollContainer.scrollWidth / 2) {
          // Assumes images are duplicated for a seamless loop
          scrollAmount = 0;
        }
        scrollContainer.scrollLeft = scrollAmount;
        scrollAmount += step;
        requestAnimationFrame(scrollHorizontally);
      }
    }

    // function scrollHorizontally(timestamp) {
    //   if (!lastTime) lastTime = timestamp;
    //   const deltaTime = timestamp - lastTime;

    //   // Calculate distance based on time and speed
    //   const distance = step * deltaTime;

    //   if (scrollContainer) {
    //     scrollContainer.scrollLeft += distance;
    //     lastTime = timestamp;
    //     requestAnimationFrame(scrollHorizontally);
    //   }
    // }

    scrollHorizontally();

    return () => {}; // Cleanup if needed
  }, []);
  return (
    <StyledGridContainer style={{ ...containerStyle }} container spacing={2}>
      <div ref={scrollContainerRef} className="logo-scroll-container">
        <div style={{ marginRight: imageMargin }}>
          <a href="https://ai-village.eu/" target="_blank" rel="noreferrer">
            <img
              src="/img/logos/ai-village-logo.png"
              style={{ height: 32 }}
              alt="AI Village Logo"
            />
          </a>
        </div>
        <div style={{ marginRight: imageMargin }}>
          <a href="https://www.doctronic.de/" target="_blank" rel="noreferrer">
            <img
              src="/img/logos/doctronic_logo_2024.svg"
              alt="Doctronic Logo"
            />
          </a>
        </div>
        <div style={{ marginRight: imageMargin }}>
          <a
            href="https://www.hypeinnovation.de/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/img/logos/hype.jpg" alt="Hype Logo" />
          </a>
        </div>
        <div style={{ marginRight: imageMargin }}>
          <a href="https://tricept.de/" target="_blank" rel="noreferrer">
            <img src="/img/logos/tricept.jpg" alt="Tricept Logo" />
          </a>
        </div>
        <div style={{ marginRight: imageMargin }}>
          <a href="https://ai-village.eu/" target="_blank" rel="noreferrer">
            <img
              src="/img/logos/ai-village-logo.png"
              style={{ height: 32 }}
              alt="AI Village Logo"
            />
          </a>
        </div>
        <div style={{ marginRight: imageMargin }}>
          <a href="https://www.doctronic.de/" target="_blank" rel="noreferrer">
            <img
              src="/img/logos/doctronic_logo_2024.svg"
              alt="Doctronic Logo"
            />
          </a>
        </div>
        <div style={{ marginRight: imageMargin }}>
          <a
            href="https://www.hypeinnovation.de/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/img/logos/hype.jpg" alt="Hype Logo" />
          </a>
        </div>
        <div style={{ marginRight: imageMargin }}>
          <a href="https://tricept.de/" target="_blank" rel="noreferrer">
            <img src="/img/logos/tricept.jpg" alt="Tricept Logo" />
          </a>
        </div>
      </div>
    </StyledGridContainer>
  );
}

const StyledGridContainer = styled(Grid)`
  background-color: white;
  color: black;
  padding: 12px 0;
  .logo-scroll-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
    }
    img {
      flex: 0 0 auto; /* Do not grow, do not shrink, and base width on the width property */
      height: 24px;
    }
  }
`;
