import { Hero } from "../components/Hero";
import Container from "@mui/material/Container";
import { Section } from "../components/Section";
import { content } from "../content/content-job-advisers";
import { SectionContainer } from "../components/SectionContainer";
import { Calendar } from "../components/Calendar";
import { dates } from "../content/dates";
import { Footer } from "../components/Footer";
import { useRef } from "react";

function JobAdvisers() {
  const contactRef = useRef(null);

  return (
    <>
      <Hero
        image="/img/cropped-working-on-mac-AdobeStock_162943505_3200x1729.jpg"
        claim={`Sicher\nin einen\nguten Job\nbegleitet.`}
        xsTop={"220px"}
        xsLeft={"20px"}
        backgroundPosition={"30% 30%"}
      />
      <Container component="main">
        <SectionContainer>
          <Section sectionData={content.firstInBonn} colspan={2} />
          <Section sectionData={content.whySuccessful} colspan={2} />
        </SectionContainer>
        <Hero
          image="/img/cropped-costs-next-academy-G6k_uEjXygE-unsplash_3200x1730.jpg"
          claim={`Was\nkostet\ndas\ndenn?`}
          xsTop={"190px"}
          xsRight={"20px"}
          xsTextAlign={"right"}
          smTop={"190px"}
          smRight={"60px"}
          smTextAlign={"right"}
          mdTop={"190px"}
          mdRight={"60px"}
          mdTextAlign={"right"}
          backgroundPosition={"45% 50%"}
        />
        <SectionContainer>
          <Section sectionData={content.azavCertified} colspan={2} />
        </SectionContainer>
        <Hero
          image="/img/cropped-course-marvin-meyer-SYTO3xs06fU-unsplash_3200x1730.jpg"
          claim={`Der Lern-Alltag`}
          xsTop={"180px"}
          xsLeft={"40px"}
          smTop={"180px"}
          smLeft={"150px"}
        />
        <SectionContainer>
          <Section sectionData={content.moreThanDev} colspan={2} />
        </SectionContainer>
        <Hero
          image="/img/cropped-dates-jason-goodman-0K7GgiA8lVE-unsplash_3200x1730.jpg"
          claim={`Termine`}
          xsTop={"390px"}
          xsLeft={"130px"}
          smTop={"180px"}
          smLeft={"calc(20px + 10vw)"}
          backgroundPosition={"35%"}
        />
        <Calendar
          dates={dates}
          type="jobAdvisers"
          onSelectInfo={() => {
            if (contactRef) {
              contactRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }}
        />
        <Hero
          ref={contactRef}
          image="/img/cropped-contact-AdobeStock_352375722_3200x1730.jpg"
          claim={`Mehr Infos\nJederzeit`}
          xsTop={"150px"}
          xsRight={"20px"}
          xsTextAlign={"right"}
          smTop={"180px"}
          smLeft={"8vw"}
          smTextAlign={"left"}
          backgroundPosition={"100%"}
        />
        <SectionContainer>
          <Section sectionData={content.contact} colspan={2} />
        </SectionContainer>
        <Hero
          image="/img/cropped-new-life-AdobeStock_358963121_3200x1730.jpg"
          claim={`Und dann beginnt\nein neues Leben`}
          xsTop={"240px"}
          xsLeft={"20px"}
          // xsRight={"0px"}
          // xsTextAlign={"left"}
          smTop={"240px"}
          smLeft={"10vw"}
          // smRight={"0px"}
          // smTextAlign={"left"}
          // mdTop={"0px"}
          // mdLeft={"0px"}
          // mdRight={"0px"}
          // mdTextAlign={"left"}
          // color={"white"}
          backgroundPosition={"80%"}
        />
        <Footer omit="jobAdvisers" />
      </Container>{" "}
    </>
  );
}

export default JobAdvisers;
