export const content = {
  firstInBonn: {
    headline: "Taktsoft Campus Talents - #1 IT-Bootcamp in Bonn",
    columns: [
      `
IT-Bootcamps sind eine neue, boomende Form der Ausbildung. In nur vier Monaten 
werden Quereinsteiger:innen und andere Talente zu gesuchten Fachkräften ausgebildet - zum 
Beispiel als Frontend Developer Web & Mobile App.

Das Training ist sehr intensiv und fordernd - die Absolvent:innen des Bootcamps 
haben neben ihrem Talent große Motivation und Leistungsbereitschaft bewiesen. 
  `,
      `
Die Teilnehmer:innen haben gelernt, in agilen Teams zu arbeiten und sich Inhalte 
aktiv zu erarbeiten. In einem einmonatigen, persönlichen Abschlussprojekt 
können die Absolvent:innen Ihre Fähigkeiten beweisen.

Zur Abschlusspräsentation und dem anschließenden Kennenlernen 
laden wir Unternehmen ein, die auf der Suche nach IT-Talenten sind.
    `,
    ],
  },
  freshMinds: {
    headline: "Frische Kräfte für Ihr Team...",
    columns: [
      `Sie sind auf der Suche nach gut qualifizierten Fachkräften? Im Rahmen des 
Bootcamps haben Sie exklusiven Erstkontakt zu den Teilnehmer:innen. 

Bereits während des Bootcamps haben Sie die Gelegenheit, Ihr Unternehmen den
TeilnehmerInnen zu präsentieren. Zum Abschluss des Bootcamps haben Sie als eines
der ersten Unternehmen die Möglichkeit, Jobangebote auszusprechen.
`,
    ],
  },
  orRefreshment: {
    headline: "...oder eine Auffrischung",
    columns: [
      `Das Coding Bootcamp ist außerdem ein günstiger und effektiver Weg, eigene Mitarbeiter:innen 
    beruflich weiter zu entwickeln. Egal ob Projektmanager:innen, die zurück zu ihren 
    Wurzeln der Technik wollen oder Programmierer:innen die zum Stand der Technik aufschließen wollen. 
    In kürzester Zeit werden Mitarbeiter:innen in den aktuellen Web/App-Technologien geschult.`,
    ],
  },
  threeGoals: {
    headline: "Das Bootcamp verfolgt drei Ziele",
    columns: [
      `
1. Die Teilnehmer:innen bekommen die solide fachliche Ausbildung, 
iOS/Android-Apps und Web-Apps zu entwickeln.

2. Die Absolvent:innen haben gelernt, sich selbst weiterzubilden 
und die Wissensquellen im Netz effektiv zu nutzen.

3. In drei Projektwochen lernen die Teilnehmer:innen das Arbeiten 
im agilen Team - optional mit der Zertifizierung als Scrum Master.
    `,
      `
Zu diesem Zweck verbinden wir Lernphasen (Unterricht und Einzel-/Gruppenarbeit) mit 
Projektphasen (Teamarbeit) und persönlichen Coachings. 

In einem vierwöchigen persönlichen Abschlussprojekt beweisen die Teilnehmer:innen 
dann ihr individuelles Können.

Garantiert ist: Alle Absolvent:innen des Coding Bootcamps haben ein außerordentliches 
Maß an Talent, Motivation und Leistungsbereitschaft bewiesen.
  `,
    ],
  },
  learningOnTheJob: {
    headline: "Lifelong Learning",
    columns: [
      `
Gestern PHP und Webseiten, heute React, Apps und KI.  

Wer nicht regelmäßig am Ball bleibt, kann auch mal den Stand der 
Technik aus den Augen verlieren. Das IT-Bootcamp ist der schnellste Weg, 
da wieder zum Puls der Zeit zurückzufinden und die Möglichkeiten
der Digitalisierung voll auszunutzen. 
`,
      `
Nutzen Sie die umfangreichen Förderprogramme der Agentur für Arbeit.

In der beruflichen Weiterbildung können auch einzelne Module und Themen
besucht werden und Teams im Projekt weitergebildet werden (Learning on the job). 
Sprechen Sie uns dazu an.
      `,
    ],
  },
  contact: {
    headline: "Kontaktieren Sie uns",
    columns: [
      `
Sie möchten das Bootcamp besuchen und sich als Arbeitgeber:in vorstellen? 

Sie möchten bei der Präsentation der Abschlussarbeiten Kontakt mit 
den Absolvent:innen aufnehmen?
`,
      `
Sie möchten eigene Teams auf neuen Technologien schulen?

Kontaktieren Sie Ihre Ansprechpartnerin Silke Grotegut 
per Mail an <talents@taktsoft.com> oder telefonisch unter [0175 5746386](tel:+491755746386). 
    `,
    ],
  },
};
