import Grid from "@mui/material/Grid";
import { Button } from "./Button";
import ReactGA from "react-ga4";

export const PersonalMeeting = () => {
  return (
    <>
      <Grid item xs={12}>
        <h2>Persönliches Gespräch</h2>
        <p>
          Du hast Fragen oder bist unsicher, ob Programmieren das Richtige für
          Dich ist? Unsere Coaches helfen Dir im persönlichen Gespräch gerne
          weiter. Vereinbare jetzt unverbindlich und unkompliziert einen
          Telefontermin.
        </p>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 4, marginBottom: 4 }}>
        <Button
          sx={{ width: "100%" }}
          onClick={() => {
            ReactGA.event("calendly_opened", {
              conversion_type: "contact_request",
              value: 100,
            });
            window.open("https://calendly.com/ralf-siewert/bootcamp", "_blank");
          }}
        >
          Jetzt Termin vereinbaren
        </Button>
      </Grid>
      {/* <Grid item xs={12} sx={{ marginBottom: 4 }}>
        <h3>Oder ruf uns direkt an:</h3>
        Silke Grotegut:{" "}
        <a
          href="tel:0175 5746386"
          onClick={() =>
            ReactGA.event("phone_number_clicked", {
              conversion_type: "contact_request",
              value: 40,
            })
          }
        >
          0175 5746386
        </a>
        <br />
        Ralf Siewert:{" "}
        <a
          href="tel:0160 92180131"
          onClick={() =>
            ReactGA.event("phone_number_clicked", {
              conversion_type: "contact_request",
              value: 40,
            })
          }
        >
          0160 92180131
        </a>
      </Grid> */}
    </>
  );
};
