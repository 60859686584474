import { css } from "@emotion/react";

export const globalStyles = css`
  @font-face {
    font-family: "ZillaSlab";
    src: url("fonts/Zilla_Slab/ZillaSlab-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ZillaSlab";
    src: url("fonts/Zilla_Slab/ZillaSlab-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: "ZillaSlab";
    src: url("fonts/Zilla_Slab/ZillaSlab-SemiBold.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "ZillaSlab";
    src: url("fonts/Zilla_Slab/ZillaSlab-SemiBoldItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "ZillaSlab";
    src: url("fonts/Zilla_Slab/ZillaSlab-Bold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "ZillaSlab";
    src: url("fonts/Zilla_Slab/ZillaSlab-BoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
  }
  :root {
    --primary-color: #11acff;
    --secondary-color: #ea5153;
    --font-family-regular: "Roboto", sans-serif;
    --font-family-headlines: "ZillaSlab", serif;
  }
  body {
    font-family: var(--font-family-regular);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-family-headlines);
    color: var(--secondary-color);
    margin-block-start: 0;
    margin-bottom: 0;
  }
  h2 {
    font-size: 2.6rem;
    line-height: 1;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    hyphens: auto;
  }
`;
