import ReactMarkdown from "react-markdown";
import { styled } from "@mui/material/styles";
import { Avatar, Grid } from "@mui/material";
import { contentTrainers } from "../../content/content-trainers";

export const Trainers = ({ trainerIds }) => {
  return (
    <Grid item container columnSpacing={5} direction={"row"} wrap={"wrap"}>
      {contentTrainers.map((trainer) => {
        // console.log("trainer ", trainer);
        if (trainerIds.find((trainerId) => trainerId === trainer.id))
          return (
            <Grid
              key={trainer.img}
              item
              xs={12}
              // sm={6}
              container
              columnSpacing={2}
              direction={"row"}
              sx={{
                marginBottom: 1,
              }}
            >
              <Grid item xs={3}>
                <Avatar
                  alt={trainer.name}
                  src={`/img/trainers/${trainer.img}`}
                  sx={{ width: 64, height: 64 }}
                />
              </Grid>
              <Grid item xs={9} container>
                <Grid item xs={12}>
                  <h3>{trainer.name}</h3>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 0,
                  }}
                >
                  <StyledMarkdown>{trainer.text}</StyledMarkdown>
                </Grid>
              </Grid>
            </Grid>
          );
        else return null;
      })}
    </Grid>
  );
};

const StyledMarkdown = styled(ReactMarkdown)`
  max-width: 600px;
  & a {
    text-decoration: underline;
    color: #888;
    /*font-weight: 600; */
  }
  & p {
    margin-block-start: 0;
  }
  & h2 {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.8rem;
  }
`;
