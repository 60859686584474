import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

export const Footer = ({ omit } = "") => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let styleLarge = isLarge
    ? {
        left: 0,
        width: "100%",
      }
    : { width: "100vw", position: "relative", left: "calc(-50vw + 50%)" };

  return (
    <Box sx={{ marginTop: 3 }}>
      <div
        style={{
          backgroundColor: "#f2f2f2",
          paddingTop: 30,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 50,
          ...styleLarge,
        }}
      >
        <img src="/img/taktsoft-footer-logo.svg" alt="Taktsoft" width={120} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 15,
          }}
        >
          <div>
            <Typography variant="body2">
              talents@taktsoft.com <br />
              Mozartstrasse 4-10 <br />
              53115 Bonn
              <br />
              Deutschland
            </Typography>{" "}
          </div>
          <div>
            <Typography variant="body2">
              {omit === "students" ? null : (
                <>
                  <a href="/teilnehmer">Informationen für Teilnehmer</a>
                  <br />
                </>
              )}
              {omit === "partners" ? null : (
                <>
                  <a href="/partner">Informationen für Partner-Unternehmen</a>
                  <br />
                </>
              )}
              {omit === "jobAdvisers" ? null : (
                <>
                  <a href="/arbeitsvermittler">
                    Informationen für Arbeits-Vermittler
                  </a>
                </>
              )}
              <br />
              <a
                href="https://www.linkedin.com/company/taktsoft-campus-talents"
                target="_blank"
                rel="noreferrer"
              >
                Taktsoft Campus Talents auf LinkedIn
              </a>
            </Typography>
          </div>
          <div style={{ textAlign: isMobile ? "left" : "right" }}>
            <Typography variant="body2">
              <a
                href="https://www.taktsoft.com/impressum"
                target="_blank"
                rel="noreferrer"
              >
                Impressum
              </a>
              <br />
              <a
                href="https://www.taktsoft.com/datenschutz"
                target="_blank"
                rel="noreferrer"
              >
                Datenschutz
              </a>
              <br />
            </Typography>
          </div>
        </div>
        <br />

        <div>
          <img
            src="/img/Certqua_Zeichen_AZAV_gross_4c.png"
            alt="Certqua Siegel für AZAV Zertifizierung"
            width={240}
          />
        </div>
        <Typography variant="caption">
          <br />
          Photos by Marvin Meyer, Jason Goodman, Next Academy, Tai Bui on
          unsplash, stock.adobe.com and Marlene Mondorf
        </Typography>
      </div>
    </Box>
  );
};
