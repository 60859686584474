import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export function Teaser({ teaserItems, containerStyle = {}, textStyle = {} }) {
  return (
    <StyledGridContainer style={{ ...containerStyle }} container spacing={2}>
      {teaserItems.map((teaserItem) => {
        return (
          <Grid item container gap={1} xs={12} md={6}>
            <CheckCircleIcon style={{ ...textStyle }} />{" "}
            <TeaserText>{teaserItem.text}</TeaserText>
          </Grid>
        );
      })}
    </StyledGridContainer>
  );
}

const StyledGridContainer = styled(Grid)`
  background-color: var(--secondary-color);
  color: white;
  padding: 24px 24px;
`;

const TeaserText = styled(Typography)`
  font-size: 1.2em;
  font-weight: bold;
  @media (max-width: 480px) {
    font-size: 1.1em;
  }
  @media (max-width: 400px) {
    font-size: 1em;
  }
`;
