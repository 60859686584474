import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Button } from "../Button";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { ThankYou } from "../ThankYou";
import validator from "validator";
import ReactGA from "react-ga4";

const allowedPhoneChars = "0123456789()+/- ";

export const InfoForm = () => {
  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
  });
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setContact({ ...contact, [field]: value });
    setErrors({ ...errors, [field]: null });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const newErrors = Object.keys(contact).reduce((acc, key) => {
        return { ...acc, [key]: null };
      }, {});
      if (!contact.firstName.length) {
        newErrors.firstName = "Bitte gib Deinen Namen an";
      }
      if (!contact.lastName.length) {
        newErrors.lastName = "Bitte gib Deinen Namen an";
      }
      if (!validator.isEmail(contact.email)) {
        newErrors.email = "Bitte gib eine gültige E-Mail an";
      }
      if (!contact.phone.length) {
        newErrors.phone = "Bitte gib Deine Telefonnummer an";
      }
      for (const error of Object.values(newErrors)) {
        if (error) {
          console.log(newErrors);
          setErrors(newErrors);
          return;
        }
      }

      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_MAILER_URL, {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phone: contact.phone,
        bootcamp: "Infopaket",
      });
      console.log({ response });
      ReactGA.event("info_pack_requested", {
        conversion_type: "contact_request",
        value: 10,
      });

      setConfirmed(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (confirmed) {
    return <ThankYou />;
  }

  return (
    <Box
      component="form"
      noValidate
      // autoComplete="on"
      sx={{ display: "flex", flexDirection: "column", maxWidth: 600 }}
      onSubmit={handleSubmit}
    >
      <Grid container rowSpacing={2} sx={{ marginBottom: 4 }}>
        <Grid item xs={12}>
          <h3>Oder fordere unser Infopaket an:</h3>
          <StyledTextField
            size="small"
            value={contact.firstName}
            label="Vorname"
            error={errors.firstName ? true : false}
            helperText={errors.firstName || ""}
            onChange={(event) => handleChange("firstName", event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            size="small"
            value={contact.lastName}
            error={errors.lastName ? true : false}
            label="Name"
            helperText={errors.lastName || ""}
            onChange={(event) => handleChange("lastName", event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            size="small"
            value={contact.email}
            error={errors.email ? true : false}
            type="email"
            label="E-Mail"
            helperText={errors.email || ""}
            onChange={(event) => handleChange("email", event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            size="small"
            value={contact.phone}
            label="Telefon"
            error={errors.phone ? true : false}
            helperText={errors.phone || ""}
            onChange={(event) => {
              for (const char of event.target.value.split("")) {
                if (!allowedPhoneChars.includes(char)) {
                  return;
                }
              }

              handleChange("phone", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button disabled={loading} type="submit" sx={{ width: "100%" }}>
            Infopaket bekommen
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const StyledTextField = styled(TextField)`
  width: 100%;
`;
