import React from "react";
import Grid from "@mui/material/Grid";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";

export const Calendar = ({ dates, type, onSelectInfo = () => {} }) => {
  const navigate = useNavigate();

  const onSelectApply = (id) => {
    console.log("apply");
    navigate(`/bewerbung?bootcamp=${id}`);
  };

  return (
    <Grid container sx={{ paddingTop: 3, paddingBottom: 5 }}>
      <Grid xs={12} item>
        <h2>Termine</h2>
      </Grid>
      {dates.map((date, index) => {
        return (
          <Grid
            item
            container
            columnSpacing={5}
            key={index}
            sx={{ marginBottom: 2, marginTop: 2 }}
          >
            <Grid item xs={12} sx={{ marginBottom: 1 }}>
              <h2 style={{ fontSize: "2.4em" }}>{date.date}</h2>
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginBottom: 1 }}>
              <h3>{date.title}</h3>
              <p style={{ margin: 0 }}>
                {type === "students" ? date.infoStudents : date.infoOther}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column", gap: 1 }}
            >
              <Button
                sx={{ width: "100%" }}
                onClick={() => {
                  console.log("onSelectApply ", onSelectApply);
                  onSelectApply(index);
                }}
              >
                Jetzt bewerben
              </Button>
              <Button
                sx={{ width: "100%" }}
                variant="outlined"
                onClick={() => {
                  onSelectInfo();
                }}
              >
                Mehr Infos & Trainer kennenlernen
              </Button>
              {date.kursnetUrl ? (
                <Button
                  sx={{ width: "100%" }}
                  variant="outlined"
                  href={date.kursnetUrl}
                  target="_blank"
                >
                  <img
                    src="/img/ba-logo.png"
                    alt="Arbeitsagentur"
                    height={"20px"}
                  ></img>
                  In KURSNET öffnen
                </Button>
              ) : null}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
