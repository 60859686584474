import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import React from "react";

export const Button = ({ children, ...props }) => {
  return <StyledMuiButton {...props}>{children}</StyledMuiButton>;
};

const StyledMuiButton = styled(MuiButton)`
  transition: all 0.2s;
  &:active {
    box-shadow: none;
    transform: scale(0.98);
  }
`;
