export const contentTrainers = [
  {
    id: "silke",
    img: "silke_cropped.jpg",
    name: "Silke Grotegut",
    text: `Career Coach, Bewerbungstrainerin,
    LinkedIn-Expertin & Hundeflüsterin.
    `,
  },
  {
    id: "ralf",
    img: "ralf.jpg",
    name: "Ralf Siewert",
    text: `Coder, Founder, Coach,
    Schach-Enthusiast & Doppelkopf-Liebhaber.
    `,
  },
  {
    id: "staicy",
    name: "Staicy Stadtler",
    img: "staicy.jpg",
    text: `Coach, Astrophysikerin,
    Choreographin & Nintendo-Evangelistin`,
  },
  {
    id: "ernst",
    name: "Ernst Stolz",
    img: "ernst.jpeg",
    text: `Coach, Coder, Fitness Instructor & YouTuber`,
  },
  {
    id: "thomas",
    img: "thomas_cropped.jpg",
    name: "Thomas Görldt",
    text: `Award-winning Mobile App Coder & 
    Hunde-Lastenrad-Fahrer.
    `,
  },
  {
    id: "sascha",
    img: "sascha_interim.jpg",
    name: "Sascha Rose",
    text: `Coach, Coder, Technik Enthusiast, 
    IHK-Ausbilder & Campervan-Kapitän.
    `,
  },
  {
    id: "ulrich",
    img: "ulrich_cropped.jpg",
    name: "Ulrich Klostermann",
    text: `Coder, Founder, Coach,
    IHK-Ausbilder & Foto-Freund.
    `,
  },
];
