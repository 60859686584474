export const content = {
  firstInBonn: {
    headline: "Taktsoft Campus Talents - #1 IT-Bootcamp in Bonn",
    columns: [
      `
IT-Bootcamps sind eine neue, boomende Form der Ausbildung. In nur vier Monaten 
werden Quereinsteiger:innen und andere Talente zu gesuchten Fachkräften ausgebildet - zum 
Beispiel als Frontend Developer Web & Mobile App.

Das Training ist sehr intensiv und fordernd - die Absolvent:innen des Bootcamps 
haben neben ihrem Talent große Motivation und Leistungsbereitschaft bewiesen. 
  `,
      `
Die Teilnehmer:innen haben gelernt, in agilen Teams zu arbeiten und sich Inhalte 
aktiv zu erarbeiten. In einem einmonatigen, persönlichen Abschlussprojekt 
können die Absolvent:innen Ihre Fähigkeiten beweisen.

Zur Abschlusspräsentation und dem anschließenden Kennenlernen 
laden wir Unternehmen ein, die auf der Suche nach IT-Talenten sind.
    `,
    ],
  },
  whySuccessful: {
    headline: "Was macht es so erfolgreich?",
    columns: [
      `
Die Vermittlungsquoten sind sehr hoch. 

Die Absolvent:innen des Coding Bootcamps sind gut ausgebildete Fachkräfte, die ihr Talent 
und ihre Motivation bewiesen haben - das ist das beste Bewerbungszeugnis. 
`,
      `
Die kurze Dauer der Ausbildung und die guten Erfolgsaussichten senken die Hürde für einen 
beruflichen Neuanfang enorm - eine Bootcamp-Teilnahme braucht keinen großen 
Mut, nur etwas Talent und eine große Motivation, um erfolgreich zu sein.
    `,
    ],
  },
  azavCertified: {
    headline: "AZAV zertifiziert",
    columns: [
      `
Taktsoft und die Bootcamp-Maßnahme werden AZAV-zertifiziert sein.
Alternativ bieten wir Teilnehmer:innen risikoarme und günstige 
Finanzierungs-Angebote für die Weiterbildung.
    `,
      `
Damit das Geld gut investiert ist, testen wir die Bewerber:innen im Vorfeld 
intensiv auf die zwei zentralen Voraussetzungen für das Bootcamp: Talent und Motivation.
    `,
    ],
  },

  moreThanDev: {
    headline: "Mehr als Programmieren",
    columns: [
      `
Unser Ziel ist es, die Teilnehmer:innen so zu qualifizieren, dass sie nach kurzem, intensivem 
Training in den Job gehen und sich dort weiter entwickeln können.

Dazu gehört die fachliche Qualifikation, effektive Recherche- und Selbstlern-Methoden 
und das Arbeiten in agilen Teams - optional mit Zertifizierung zum Scrum Master.
      `,
      `
Die Bewerber:innen werden im Vorfeld eingehend auf ihr Talent und 
ihre Motivation getestet und geprüft. 
      
Die Maßnahme besteht aus drei Monaten Lernphase mit Unterricht, Einzel-/Gruppenarbeiten, 
Projektwochen und persönlichen Coachings, gefolgt von einem Monat für das persönliche 
Abschlussprojekt. Wie in der guten, alten Übungsfirma.
      `,
    ],
  },
  contact: {
    headline: "Kontaktieren Sie uns",
    columns: [
      `
Als Arbeitsvermittler:in haben Sie selbst Fragen zum IT-Bootcamp? Oder Sie kennen 
die Fragen Ihrer Kund:innen, die noch unbeantwortet sind? 
`,
      `
Bitte kontaktieren Sie uns per Mail an <talents@taktsoft.com> oder telefonisch:

Silke Grotegut ([0175 5746386](tel:+491755746386))  
Ulrich Klostermann ([0177 2450600](tel:+491772450600))
    `,
    ],
  },
};
