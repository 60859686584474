import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#11acff",
    },
    secondary: {
      main: "#ea5153",
    },
    text: {
      primary: "rgba(0,0,0,0.87)",
      secondary: "#ea5153",
      gray: "#7f7f7f",
    },
  },
  typography: {
    h2: {
      fontFamily: "var(--font-family-headlines)",
      color: "var(--secondary-color)",
      fontSize: "1.8rem",
      fontWeight: 600,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginBottom: 0,
    },
    body2: {
      fontSize: "0.9rem",
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "md",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableRipple: true,
      },
      styleOverrides: {
        contained: {
          paddingRight: 20,
          paddingLeft: 20,
          paddingTop: 4,
          paddingBottom: 4,
        },
        containedPrimary: {
          color: "#fff",
          textTransform: "none",
          fontSize: "1.1rem",
          fontWeight: 600,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#aaa",
        },
      },
    },
  },
});
