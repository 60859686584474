import { Card as MuiCard, CardContent } from "@mui/material";
export function Card({ children }) {
  return (
    <MuiCard sx={{ height: "100%" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "theme.palette.primary",
        }}
      >
        {children}
      </CardContent>
    </MuiCard>
  );
}
