import React from "react";
import { Container, Paper } from "@mui/material";
import { ApplicationForm } from "../components/ApplicationForm";
import { Hero } from "../components/Hero";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { Footer } from "../components/Footer";
import { useRatioPanorama } from "../hooks/useRatioPanorama";

function Application() {
  const theme = useTheme();
  let [searchParams] = useSearchParams();
  const bootcamp = searchParams.get("bootcamp");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  let panorama = useRatioPanorama();

  return (
    <div style={{ position: "relative" }}>
      {!isMobile && (
        <Hero
          image="/img/enrol-AdobeStock_308251335.jpg"
          // claim={isLarge && `Jetzt bewerben!`}
          xsTop={"340px"}
          xsLeft={"calc(20px + 18vw)"}
          smTop={"100px"}
          smLeft={"calc(15px + 4vw)"}
          backgroundPosition={"50%"}
        />
      )}

      <Container
        maxWidth="sm"
        component="main"
        sx={{
          // paddingTop: 4,
          // paddingBottom: 4,
          paddingLeft: 0,
          paddingRight: 0,
          position: "relative",
          top: !isMobile ? (panorama ? -150 : -250) : 0,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 5,
            borderRadius: 3,
            marginBottom: 5,
          }}
        >
          <ApplicationForm bootcamp={bootcamp} />
        </Paper>
        <Footer />
      </Container>
    </div>
  );
}
export default Application;
