import { useState, useEffect } from "react";

export const useRatioPanorama = () => {
  const [panorama, setPanorama] = useState(false);

  useEffect(() => {
    // console.log("panorama", panorama);
  }, [panorama]);

  useEffect(() => {
    function handleEvent() {
      const ratio = window.innerWidth / window.innerHeight;
      // console.log("ratio ", ratio);
      if (ratio > 16 / 9) setPanorama(true);
      else setPanorama(false);
    }
    handleEvent();
    window.addEventListener("resize", handleEvent);
    return () => {
      window.removeEventListener("resize", handleEvent);
    };
  }, []);

  return panorama;
};
