import ReactPlayer from "react-player";

export function VideoPlayer({ url, style, width, height }) {
  return (
    <div
      style={{
        borderRadius: 20,
        position: "relative",
        paddingTop: 10,
      }}
    >
      <ReactPlayer
        url={url}
        width={width ? width : 300}
        height={height ? height : 300}
        controls={true}
        style={{
          ...style,
          borderRadius: 20,
          overflow: "hidden",
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
}
