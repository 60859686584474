import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { dates } from "../../content/dates";

import { useState } from "react";
import { Button } from "../Button";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { ThankYou } from "../ThankYou";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import validator from "validator";

const allowedPhoneChars = "0123456789()+/- ";

export const ApplicationForm = ({ bootcamp }) => {
  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    selectedBootcamp: bootcamp || 0,
  });
  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    selectedBootcamp: null,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const handleChange = (field, value) => {
    setContact({ ...contact, [field]: value });
    setErrors({ ...errors, [field]: null });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const newErrors = Object.keys(contact).reduce((acc, key) => {
        return { ...acc, [key]: null };
      }, {});
      if (!contact.firstName.length) {
        newErrors.firstName = "Bitte gib Deinen Namen an";
      }
      if (!contact.lastName.length) {
        newErrors.lastName = "Bitte gib Deinen Namen an";
      }
      if (!validator.isEmail(contact.email)) {
        newErrors.email = "Bitte gib eine gültige E-Mail an";
      }
      for (const error of Object.values(newErrors)) {
        if (error) {
          console.log(newErrors);
          setErrors(newErrors);
          return;
        }
      }
      setLoading(true);
      const bootcamp = dates[contact.selectedBootcamp];
      const bootcampText = `${bootcamp.date} ${bootcamp.title}`;
      await axios.post(process.env.REACT_APP_MAILER_URL, {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phone: contact.phone,
        bootcamp: bootcampText,
      });
      setConfirmed(true);
    } catch (err) {
      console.log(console.err);
    } finally {
      setLoading(false);
    }
  };

  if (confirmed) {
    return (
      <ThankYou
        showBackButton
        onBack={() => {
          navigate("/");
        }}
      />
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: 3,
        }}
      >
        <Typography variant="h2" sx={{ textAlign: "center", marginBottom: 1 }}>
          Schön, dass Du dabei bist!
        </Typography>
        <Typography>
          Du interessierst Dich für das Bootcamp? Vereinbare hier ein
          unverbindliches und kostenloses Infogespräch.
        </Typography>

        <Typography>
          Unser Admissions-Team meldet sich bei Dir und erklärt Dir die weiteren
          Schritte im Bewerbungsprozess.
        </Typography>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-bootcamp-label">Bootcamp</InputLabel>
              <Select
                labelId="select-bootcamp-label"
                id="select-bootcamp"
                value={contact.selectedBootcamp}
                label="Bootcamp"
                onChange={(event) => {
                  handleChange("selectedBootcamp", event.target.value);
                }}
              >
                {dates.map((date, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {date.date} {date.titleShort}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              size="small"
              value={contact.firstName}
              label="Vorname"
              error={errors.firstName ? true : false}
              helperText={errors.firstName || ""}
              onChange={(event) =>
                handleChange("firstName", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              size="small"
              value={contact.lastName}
              label="Name"
              error={errors.lastName ? true : false}
              helperText={errors.lastName || ""}
              onChange={(event) => handleChange("lastName", event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              size="small"
              value={contact.email}
              type="email"
              label="E-Mail"
              error={errors.email ? true : false}
              helperText={errors.email || ""}
              onChange={(event) => handleChange("email", event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              size="small"
              value={contact.phone}
              label="Telefon (optional)"
              error={errors.phone ? true : false}
              helperText={errors.phone || ""}
              onChange={(event) => {
                for (const char of event.target.value.split("")) {
                  if (!allowedPhoneChars.includes(char)) {
                    return;
                  }
                }
                handleChange("phone", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={loading} sx={{ width: "100%" }} type="submit">
              Abschicken
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const StyledTextField = styled(TextField)`
  width: 100%;
`;
