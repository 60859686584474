import ReactMarkdown from "react-markdown";
import { styled } from "@mui/material/styles";

export function StyledMarkdown({ children }) {
  //   console.log("data in StyledMarkdown: " + data);
  return <ThisStyledMarkdown>{children}</ThisStyledMarkdown>;
}

const ThisStyledMarkdown = styled(ReactMarkdown)`
  max-width: 600px;
  & a {
    text-decoration: underline;
    color: #888;
    /*font-weight: 600; */
  }
  & p {
    hyphens: auto;
    margin-block-end: 0;
    font-size: 1.2rem;
  }
  & h2 {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.8rem;
  }
`;
