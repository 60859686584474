import { forwardRef } from "react";
import Grid from "@mui/material/Grid";

export const SectionContainer = forwardRef(
  ({ children, paddingBottom }, ref) => {
    console.log("paddingBottom ", paddingBottom);
    return (
      <Grid
        ref={ref}
        container
        columnSpacing={5}
        sx={{
          paddingTop: 3,
          paddingBottom: paddingBottom !== undefined ? paddingBottom : 5,
        }}
      >
        {children}
      </Grid>
    );
  }
);
