import React from "react";
import { Box } from "@mui/material";
import { Button } from "../Button";

export const ThankYou = ({ showBackButton, onBack }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
      }}
    >
      <h2>Danke für Dein Interesse!</h2>
      <p style={{ fontSize: 60, margin: 0 }}>📨</p>
      <p style={{ paddingLeft: 40, paddingRight: 40, textAlign: "center" }}>
        Wir melden uns in Kürze bei Dir, um alles Weitere persönlich
        abzustimmen.
      </p>
      {showBackButton && <Button onClick={onBack}>Zur Startseite</Button>}
    </Box>
  );
};
